import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
  // mode: 'history',
  // hash: false,
  base: "pay",
  routes: [{
      path: '/',
      name: 'payInicio_es',
      component: () => import('@/views/pay/es/inicio')
    },
    {
      path: '/soluciones-de-pago',
      name: 'page_pay1_es',
      component: () => import('@/views/pay/es/solucion')
    },
    {
      path: '/solucion/pagos-con-tpv-tienda',
      name: 'page_pay1.1_es',
      component: () => import('@/views/pay/es/solucion1')
    },
    {
      path: '/solucion/pagos-online',
      name: 'page_pay1.2_es',
      component: () => import('@/views/pay/es/solucion2')
    },
    {
      path: '/solucion/pagos-por-centralita',
      name: 'page_pay1.3_es',
      component: () => import('@/views/pay/es/solucion3')
    },
    {
      path: '/servicio',
      name: 'page_pay2_es',
      component: () => import('@/views/pay/es/servicio')
    },
    {
      path: '/servicio/servicio-dcc',
      name: 'page_pay2.1_es',
      component: () => import('@/views/pay/es/servicio1')
    },
    {
      path: '/servicio/servicio-de-propina',
      name: 'page_pay2.2_es',
      component: () => import('@/views/pay/es/servicio2')
    },
    {
      path: '/partners-desarrolladores',
      name: 'page_pay3_es',
      component: () => import('@/views/pay/es/partners')
    },
    {
      path: '/partners-desarrolladores/hazte-partner',
      name: 'page_pay3.1_es',
      component: () => import('@/views/pay/es/partners1')
    },
    {
      path: '/partners-desarrolladores/encuentra-un-partner',
      name: 'page_pay3.2_es',
      component: () => import('@/views/pay/es/partners2')
    },
    {
      path: '/partners-desarrolladores/plugins',
      name: 'page_pay3.3_es',
      component: () => import('@/views/pay/es/partners3')
    },
    {
      path: '/partners-desarrolladores/desarrolladores',
      name: 'page_pay3.4_es',
      component: () => import('@/views/pay/es/partners4')
    },
    {
      path: '/partners-desarrolladores/logotipos',
      name: 'page_pay3.5_es',
      component: () => import('@/views/pay/es/partners5')
    },
    {
      path: '/contacto',
      name: 'page_pay4_es',
      component: () => import('@/views/pay/es/contact')
    },
    {
      path: '/contrata',
      name: 'page_pay5_es',
      component: () => import('@/views/pay/es/contrata')
    },
    {
      path: '/estudio-futuro-aceptacion-pagos/',
      name: 'page_pay6_es',
      component: () => import('@/views/pay/es/pagofuturo')
    // },
    // {
    //   path: '',
    //   name: 'payInicio_en',
    //   component: () => import('@/views/pay/en/inicio')
    // },
    // {
    //   path: '/solution',
    //   name: 'page_pay1_en',
    //   component: () => import('@/views/pay/en/solucion')
    // },
    // {
    //   path: '/solution/payments-with-tpv-store',
    //   name: 'page_pay1.1_en',
    //   component: () => import('@/views/pay/en/solucion1')
    // },
    // {
    //   path: '/solution/online-payments',
    //   name: 'page_pay1.2_en',
    //   component: () => import('@/views/pay/en/solucion2')
    // },
    // {
    //   path: '/solution/payments-by-phone',
    //   name: 'page_pay1.3_en',
    //   component: () => import('@/views/pay/en/solucion3')
    // },
    // {
    //   path: '/service',
    //   name: 'page_pay2_en',
    //   component: () => import('@/views/pay/en/servicio')
    // },
    // {
    //   path: '/service/service-dcc',
    //   name: 'page_pay2.1_en',
    //   component: () => import('@/views/pay/en/servicio1')
    // },
    // {
    //   path: '/service/service-for-tips',
    //   name: 'page_pay2.2_en',
    //   component: () => import('@/views/pay/en/servicio2')
    // },
    // {
    //   path: '/partners-desarrolladores',
    //   name: 'page_pay_en',
    //   component: () => import('@/views/pay/en/partners')
    // },
    // {
    //   path: '/partners-desarrolladores/hazte-partner1',
    //   name: 'page_pay3.1_en',
    //   component: () => import('@/views/pay/en/partners1')
    // },
    // {
    //   path: '/partners-desarrolladores/hazte-partner',
    //   name: 'page_pay3.2_en',
    //   component: () => import('@/views/pay/en/partners2')
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0
      };
    }
  },

});

router.beforeEach((to, from, next) => {

  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);


  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  if (!nearestWithMeta) return next();

  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  }).forEach(tag => document.head.appendChild(tag));

  next();
});

export default router;